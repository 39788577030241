<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Pagina não encontrada 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Voltar ao inicio
        </b-button>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
import axios from 'axios'
import store from '@/store/index'

export default {
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return this.downImg
      }
      return this.downImg
    },
  },
  async created() {
    const BASE_URL = 'https://obabingo.com/oba/public/logar'
    const TICKET_URL = 'https://obabingo.com/oba/public/api/match/purchase-ticket/'
    const axiosInstance = axios.create({ baseURL: BASE_URL })
    const createSession = async () => {
      const authParams = {
        email: 'ricardo19@obabingo.com',
        password: 'ri2020',
      }
      const resp = await axios.post(BASE_URL, authParams)
      const cookie = resp.headers['set-cookie'][0] // get cookie from request
      axiosInstance.defaults.headers.Cookie = cookie // attach cookie to axiosInstance for future requests
    }
    // send Post request to https://stackoverflow.com/protected after created session
    createSession().then(async () => {
      const dadosEvento = { id: '13850', quantity: '1' }
      // eslint-disable-next-line no-unused-vars
      await axiosInstance.post(TICKET_URL, dadosEvento).then(resp => {
      })
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
